<template>
  <div class="help-container">
    <div class="help-container-header">
      <span class="title">{{ tableData.title }}</span>
    </div>
    <div class="help-container-content" v-html="tableData.body"></div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/week";

export default {
  name: "detail",
  data() {
    return {
      active: 1,
      tableData: {},
      alarmLoading: false,
    };
  },
  methods: {
    getData() {
      this.alarmLoading = true;
      this.$http
        .findBodyByHelpInfoId(this.$store.state.helpInfoId)
        .then((res) => {
          this.tableData = res.data;
          this.alarmLoading = false;
        })
        .catch(() => {
          this.alarmLoading = false;
        });
    },
    dateFormat,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.help-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .help-container-header {
    width: 100%;
    border-bottom: 1px solid #eee;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
  }
  .help-container-content {
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #000;
  }
}
</style>
